export default {
  'consumption-date': 'DLC minimale',
  'preparation': 'Préparation',
  'not-found': 'Impossible de trouver le produit',
  'description': 'Description',
  'freshness-promise': 'Au moins <b>%{count} jour</b> après livraison',
  'freshness-promise_plural': 'Au moins <b>%{count} jours</b> après livraison',
  'freshness-warranty': 'Garantie fraicheur',
  'usage': 'Utilisation',
  'add-to-cart': 'Ajouter au panier',
  'add-to-cart-with-icon': 'Ajouter',
  'additionnal-infos': 'Informations supplémentaires',
  'free-product-description': 'Grâce à votre code',
  'labels-title': 'Labels',
  'unavailable': 'Épuisé',
  'suggestion': 'Vous adorerez sûrement',
  'promo': 'Promo %{value}',
  'destock': 'Anti-Gaspi %{value}',
  'reinsurance': {
    'ultra-fresh': 'Ultra-frais',
    'selection': 'Sélection minutieuse',
    'just-prices': 'Prix justes',
    'delivery': 'Livraison 7J/7',
  },
  'be-back': 'De retour le',
  'consumption-date-aria-label': 'DLC minimale',
  'complementary-products': 'Produits achetés fréquemment ensemble',
  'origin': 'Origine',
  'promo-range': 'du %{startDate} au %{endDate}',
  'promo-day': 'le %{startDate}',
  'similar-products': 'Produits similaires',
  'show-ratings': 'Voir tous les avis',
  'ratings-title': 'Avis clients',
  'use-by': 'DLC',
  'view-details': 'Détails du produit',
};
