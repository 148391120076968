export default {
  'title': 'Saisie Manuelle',
  'postal-code-placeholder': '75001 Paris',
  'postal-code-label': 'Code postal',
  'street-number-placeholder': '20',
  'street-number-label': 'N°',
  'street-placeholder': 'Rue de Harlay',
  'street-label': 'Libellé de la voie',
  'map-instructions':
    "Puis déplacer la carte pour placer le curseur sur votre adresse et confirmer l'emplacement",
  'map-overlay-text': 'Déplacer la carte pour placer le curseur où le livreur doit se rendre !',
  'confirm': 'Valider ma saisie manuelle',
  'map-value-error': 'Les coordonnées de votre position sont erronnées',
};
