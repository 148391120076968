import { useDelivery } from '~/src/common/hooks/useDelivery';
import { useFeatureFlag } from '~/src/common/services/FeatureFlag';
import { useNiceModal } from '~/src/common/services/ModalsManager';
import Tracker from '~/src/common/services/Tracker';
import { isPickup } from '~/src/common/utils/delivery';
import { useGetCart } from '~/src/queries/api-ecom/generated/api-ecom';
import { getDeliveryModalStep } from '~/src/screens/App/CatalogLayout/utils';

const useDeliveryActions = () => {
  const { data: cart } = useGetCart();
  const delivery = useDelivery(cart);
  const deliveryModal = useNiceModal('delivery-modal');
  const deliveryAddressModal = useNiceModal('delivery-address-modal');
  const deliverySelectSlotModal = useNiceModal('delivery-select-slot-modal');
  const isNewDeliveryProcess = useFeatureFlag('ecom-new-delivery-process');

  const handleDeliverySlotClick = () => {
    if (isNewDeliveryProcess) {
      void deliverySelectSlotModal.show();
    } else {
      void deliveryModal.show(
        deliveryModal.visible ? undefined : { initialStep: getDeliveryModalStep(delivery) },
      );
    }

    Tracker.sendEvent('user navigation menu', { 'navigation text': 'shipping slot' });
  };

  const handleDeliveryAddressClick = () => {
    if (isNewDeliveryProcess) {
      void deliveryAddressModal.show();
    } else {
      void deliveryModal.show(undefined);
    }

    Tracker.sendEvent('user navigation menu', { 'navigation text': 'shipping address' });
  };

  return {
    deliveryZoneName: delivery.deliveryZone?.name ?? delivery.shop?.name,
    deliveryAddress:
      delivery.address?.addressComponents ?? cart?.delivery?.address.addressComponents,
    timeSlot: delivery.timeSlot,
    isPickupMode: isPickup({ zoneType: delivery.deliveryZone?.type, mode: delivery.mode }),
    isDeliveryModalOpen: deliveryModal.visible,
    closeDeliveryModal: deliveryModal.remove,
    handleDeliverySlotClick,
    handleDeliveryAddressClick,
  };
};

export default useDeliveryActions;
