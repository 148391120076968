import constants from '~/src/constants';

export default {
  'back': 'Continuer mes achats',
  'delivery': 'Informations',
  'payment': 'Paiement',
  'payment-complete': 'Compléter ma commande',
  'time-left-to-order': 'Plus que %{time}',
  'no-delivery-slot-selected': 'Aucun créneau de livraison sélectionné',
  'no-address-selected': "Pas d'adresse de livraison",
  'button-delivery': 'Valider mes informations',
  'button-payment': 'Payer ma commande',
  'button-delivery-aria-label': 'Valider mes informations',
  'button-payment-aria-label': 'Payer ma commande',
  'summary': {
    'summary': 'Récapitulatif',
    'products-count_0': '0 Article',
    'products-count': '%{count} Article',
    'products-count_plural': '%{count} Articles',
    'estimated-price': 'Montant estimé',
    'estimated-price-info': 'Montant basé sur le poids estimé des produits',
    'shipping-price': 'Frais de livraison',
    'shipping-price-reduced': 'Frais de livraison réduits',
    'offer-up': 'Offert',
    'pre-auth-price': 'Pré-autorisation 15%',
    'variable-weight': 'Poids variable 15%',
    'preparation-fee': 'Frais de préparation',
    'pre-auth-price-info': 'Montant facturé uniquement en cas de pesée supérieure à l’estimation',
    'discounts': 'Réductions',
    'total': 'Total',
    'unavailable-products-count': '%{count} produit de votre panier est indisponible',
    'unavailable-products-count_plural': '%{count} produits de votre panier sont indisponibles',
    'incomplete-products-count': '%{count} produit de votre panier est incomplet',
    'incomplete-products-count_plural': '%{count} produits de votre panier sont incomplets',
    'updated-products-count': '%{count} produit de votre panier a été modifié',
    'updated-products-count_plural': '%{count} produits de votre panier ont été modifiés',
    'notification-aria-label': 'Produit du panier modifié (indisponible, incomplet ou modifié)',
  },
  'weight-explanation': {
    'title': '*Au gramme près !',
    'description': 'Vous serez débité du montant réel en fonction du poids des produits livrés.',
    'know-more': 'En savoir +',
  },
  'discount-section': {
    'title': 'Code promo & parrain',
    'promo-code-input-placeholder': 'Code promo/parrain',
    'button-aria-label': 'Entrer mon coupon',
    'my-discounts': 'Mes réductions',
    'coupon-expiration-date': "Valable jusqu'au ",
    'free-shipping': 'Livraison offerte',
    'free-product': 'Produit offert',
    'free-product_plural': 'Produits offerts',
    'unavailable-modal-title': 'Offre indisponible',
    'unavailable-coupon': 'Indisponible',
    'unavailable-code-promo-coupons-title': 'Offre indisponible',
    'max-discount-coupon': '(Remise max. de %{maxDiscount}€)',
    'error-code-promo': "Impossible d'utiliser le code promo",
  },
  'missing-products-section': {
    'title': 'Remplacer un produit manquant',
    'aria-label': 'Remplacer un produit manquant',
    'description': 'Nous choisissons pour vous la meilleure alternative : ',
    'tags': {
      'similar-product': 'produit proche uniquement',
      'similar-weight': 'poids similaire',
      'similar-price': 'pas de surfacturation',
    },
    'more-info': 'Comment ça marche ?',
  },
  'delivery-step': {
    'title-delivery': 'Ma livraison',
    'title-collect': 'Retrait en %{name}',
    'title-drive': 'Retrait en magasin',
    'form': {
      'firstname': {
        label: 'Prénom*',
        placeholder: 'ex : Matthieu',
      },
      'lastname': {
        label: 'Nom*',
        placeholder: 'ex : Dupont',
      },
      'password': {
        label: 'Mot de passe*',
      },
      'phone': {
        label: 'Téléphone*',
        placeholder: 'ex : 06 12 34 56 78',
      },
      'address': {
        label: 'Adresse complète',
        placeholder: 'Renseigner votre adresse de livraison ici',
      },
      'address-additional-infos': {
        example:
          "Ex : Digicode 1234, bâtiment C au fond de l'allée à gauche, 3ème étage, porte de droite.",
        infos: 'Ces informations seront conservées pour votre prochaine commande',
        label: 'Instructions pour le livreur',
        placeholder: 'Saisissez vos instructions de livraison',
        warning: 'Soyez précis pour une livraison rapide !',
      },
      'billing-address': {
        'label': 'Adresse de facturation',
        'same-as-shipping-delivery': "Utiliser l'adresse du domicile",
        'same-as-shipping-pickup': "Utiliser l'adresse du point de retrait",
      },
    },
    'register-via-signup': 'Créer un compte',
    'register-via-signin': "J'ai déjà un compte",
    'signin-required': 'Champs requis',
  },
  'payment-step': {
    'title': 'Paiement',
    'submit': 'Payer ma commande',
    'secured-payment': '100% Sécurisé',
    'expired-card-message': 'La carte sélectionnée est expirée',
    'payment-error': {
      title: 'Erreur lors du paiement de votre commande',
      actiontitle: 'Action requise pour payer votre commande',
      message:
        'La transaction a été refusée avec la carte que vous avez renseignée<br><br><p>%{error}</p>',
    },
    'warning-prepay':
      'En cliquant sur le bouton « Payer ma commande », vous validez votre commande et reconnaissez votre obligation de payer celle-ci.',
    'legal-info': `En cas de modification de nos approvisionnements d'un produit entre le jour de commande et le jour de livraison, ${constants.BRAND_NAME} vous livrera le produit avec les caractéristiques disponibles au jour de livraison et vous informera de cette substitution par email. Vous disposerez ensuite de la faculté de refuser la commande lors de la livraison. Le cas échéant, le montant de la commande ne sera pas prélevé par ${constants.BRAND_NAME}. Il est précisé que tout produit indisponible et non substitué ne sera pas facturé.`,
    'alcohol-legal-info':
      'En validant l’achat d’alcool, le client s’engage à avoir plus de 18 ans. Mon Marché se réserve la possibilité de vérifier la majorité du client lors de la livraison des marchandises.',
    'preauthorization-error-message': 'Le montant du panier a changé, veuillez rafraichir la page.',
    'product-actions-dialog': {
      'title': 'Information sur votre panier',
      'content-min-order': "Le minimum de commande de %{amount} n'est pas atteint.",
      'content':
        '<p>Vous avez des produits incomplets, indisponibles ou dont le prix a changé dans votre panier.</p><br><p>Vos frais de livraison et l’éligibilité de vos coupons peuvent avoir changé.</p>',
      'confirm-button-label': 'Accepter et payer',
      'cancel-button-label': 'Voir mon panier',
    },
  },
  'error-finalize-payment':
    'Un incident est survenu lors de la finalisation de votre commande, consultez votre page “Commandes” depuis votre compte pour vérifier sa bonne prise en compte par nos équipes',
  'preparation-fee-modal': {
    title: 'Frais de préparation',
    description:
      "Pour continuer à vous préparer des commandes aux petits oignons et améliorer notre service, nous avons besoin de vous.<br /><br />C'est pour cela que nous mettons en place des frais de préparation fixes par commande. <br/><br/>Ces frais correspondent au temps passé par nos ambassadeurs du goût pour sélectionner, peser, emballer soigneusement vos délicieux produits et préparer votre sac (sans jamais écraser vos belles tomates !).<br/><br/>Une petite somme pour une grande cause !",
    close: "J'ai compris !",
  },
  'delivery-instructions-modal': {
    close: "J'ai compris !",
    description:
      "Afin d'éviter que votre livreur ait à vous appeler pour vous remettre votre commande à votre porte, veillez à donner toutes les informations nécessaires.<br/><br/>Ces informations ne sont utilisées que pour votre livraison et resteront confidentielles.",
    subtitle: 'Soyez précis pour une livraison rapide !',
    thanks: 'Merci pour nos livreurs',
    title: 'Instructions de livraison',
  },
  'optin': 'Email/SMS',
  'cgv': {
    'title': 'Conditions générales de vente (Obligatoire)',
    'label-1': "J'ai pris connaissance des",
    'label-2': 'CGV',
    'label-3': 'et je les accepte.',
    'error': 'Vous devez accepter les CGV pour payer votre commande',
  },
};
